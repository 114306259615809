import React from 'react';
import Layout from '../components/layout';
import { Button } from 'react-bootstrap';

const TestFunraise = () => (
  <>
    <Layout nav="colored-nav">
      <section className="mt130 text-center">
        <div className="text-center">
          <Button className="btn-purple p-2 d-inline-block" data-formid="2941">
            Donate
          </Button>
        </div>
      </section>
    </Layout>
  </>
);

export default TestFunraise;
